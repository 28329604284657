import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/arrow_black.svg';
import Helmet from 'react-helmet';
import data from '../../data/data.json'

const Home = () => {

    const navigate = useNavigate()
 
    return (     
        <>
            <Helmet>
                <title> CHEWYCHIYU </title>
                <meta name="title" content="CHEWYCHIYU"/>
            
                <meta name="description" content="Explore An Extensive Collection Of Ceramic Art Works." />
                
                <meta property="og:url" content="https://chewychiyu.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="CHEWYCHIYU" />
                <meta property="og:description" content="Explore An Extensive Collection Of Ceramic Art Works." />

                <meta name="twitter:card" content="summary" />
                <meta property="twitter:domain" content="chewychiyu.com" />
                <meta property="twitter:url" content="https://www.chewychiyu.com" />
                <meta name="twitter:title" content="CHEWYCHIYU" />
                <meta name="twitter:description" content="Explore An Extensive Collection Of Ceramic Art Works." />

                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
            </Helmet>

            <div class = "mt-20 px-8 md:px-20 lg:px-32 pb-8 md:pb-16 h-screen">
                <h1 class = "text-black tracking-wide font-serif border-b border-black text-4xl md:text-5xl lg:text-6xl pb-6"> Exhibitions </h1>
                <div class = "text-black tracking-wide">
                    {
                        Object.keys(data).map((path) => { 
                            return  <div class = "group hover:cursor-pointer flex justify-between items-center border-b border-black py-8" onClick={() => {navigate('/gallery/'+path , { replace: false })}}>
                                        <div class = "flex flex-col items-start">
                                            <h1 class = "font-serif text-3xl pb-2"> {data[path].title} </h1>
                                            <h1 class = "font-sans"> {data[path].count} items. {data[path].year} </h1>
                                        </div> 
                                        <Arrow class = "group-hover:-rotate-45 transition-all"/>
                                </div>
                        })
                    }         
                </div>
            </div>

        </>
    )
}

export default Home
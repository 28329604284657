import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { ReactComponent as Arrow } from '../../assets/arrow_white.svg';
import { ReactComponent as Close } from '../../assets/close_white.svg';
import { motion } from "framer-motion";
import Helmet from 'react-helmet';
import data from '../../data/data.json'

const GalleryCarousel = () => {

    const { path, id } = useParams()
    const navigate = useNavigate()

    const next = (data[path].count-1 > parseInt(id)) ? (parseInt(id) + 1) : 0
    const prev = (parseInt(id) > 0) ? (parseInt(id) - 1) : (data[path].count-1)

    return (    
        <>  
            <Helmet>
                <title> {data[path].title + " - " + (parseInt(id)+1)} </title>
                <meta name="title" content={data[path].title + " - " + (parseInt(id)+1)}/>

                <meta name="description" content="Explore An Extensive Collection Of Ceramic Art Works." />
                
                <meta property="og:url" content="https://chewychiyu.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={data[path].title + " - " + (parseInt(id)+1)}/>
                <meta property="og:description" content="Explore An Extensive Collection Of Ceramic Art Works." />

                <meta name="twitter:card" content="summary" />
                <meta property="twitter:domain" content="chewychiyu.com" />
                <meta property="twitter:url" content="https://www.chewychiyu.com" />
                <meta name="twitter:title" content= {data[path].title + " - " + (parseInt(id)+1)}/>
                <meta name="twitter:description" content="Explore An Extensive Collection Of Ceramic Art Works." />
                
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
            </Helmet>

            <div class = "absolute top-0 left-0 bg-black bg-opacity-75 text-white font-light tracking-wide">
                <motion.div className = "flex items-center justify-around h-screen w-screen" initial={{ opacity: 0 }} animate={{ opacity: 1 }} >
                    <Arrow class = "rotate-180 hover:cursor-pointer px-2 md:px-0"  onClick={() => {navigate('/gallery/' + path + '/' +  prev, { replace: false })}}/>
                    <div class="flex flex-col max-w-full h-auto w-2/3 lg:w-1/3">
                        <div class = "flex justify-end pb-4">
                            <Close class = "hover:cursor-pointer" onClick={() => {navigate('/gallery/' + path, { replace: false })}}/> 
                        </div>
                        <img src={"/gallery/"+ path + "/no" + id + ".jpeg"} alt="ceramic img" />
                        <div class = "flex justify-between pt-2">
                            <h1> {data[path].title}. {data[path].year}  </h1>
                            <h1> {parseInt(id)+1} of {data[path].count}  </h1>
                        </div>
                    </div>
                    <Arrow class = "hover:cursor-pointer px-2 md:px-0"  onClick={() => {navigate('/gallery/' + path + '/' +  next, { replace: false })}}/>
                </motion.div>
            </div>
        </>
    )
}

export default GalleryCarousel
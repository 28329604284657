import { React } from 'react';
import { useNavigate } from 'react-router-dom';

const Nav = () => {

    const navigate = useNavigate()

    return (
        <div class = "p-6 border-b border-black flex justify-between items-center text-black tracking-wide">
            <div>
                <h1 class = "hover:cursor-pointer hover:underline " onClick={() => {navigate('/' , { replace: false })}}> ○ CHEWYCHIYU </h1>
            </div>
        </div>
    )
}

export default Nav

import $ from "jquery"
import { React } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Helmet from 'react-helmet';
import data from '../../data/data.json'

const imgVariant = {
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        } 
    },
    hidden: { 
        y: 150,
        opacity: 0
    }
};

const Image = ({ path, id }) => {

    const navigate = useNavigate()

    return (
        <motion.div
        variants={imgVariant}
        initial= "hidden"
        whileInView= "visible"
        viewport={{ once: true }}
        onClick={() => {navigate('/gallery/' + path + '/' + id, { replace: false })}}
        >
        <LazyLoadImage
                class = "gallery-img"
                height={1200}
                src={"/gallery/"+ path + "/no" + id + ".jpeg"}/>
        </motion.div>
    );
};

const GalleryGrid = () => {

    const { path } = useParams()

    return (
        <>
            <Helmet>
                <title> {data[path].title} </title>
                <meta name="title" content={data[path].title}/>

                <meta name="description" content="Explore An Extensive Collection Of Ceramic Art Works." />
                
                <meta property="og:url" content="https://chewychiyu.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={data[path].title}/>
                <meta property="og:description" content="Explore An Extensive Collection Of Ceramic Art Works." />

                <meta name="twitter:card" content="summary" />
                <meta property="twitter:domain" content="chewychiyu.com" />
                <meta property="twitter:url" content="https://www.chewychiyu.com" />
                <meta name="twitter:title" content= {data[path].title}/>
                <meta name="twitter:description" content="Explore An Extensive Collection Of Ceramic Art Works." />

                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
            </Helmet>

            <div class = "mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-6 md:px-24 lg:px-44 pb-10 gap-16">
               {
                    [...Array(data[path].count).keys()].map((i) => {
                        return  <Image path={path} id={i} key={i}/>      
                    })
                }
            </div>

            <button class = "fixed bottom-32 right-px -rotate-90 translate-x-6 lg:-translate-x-8 border border-black rounded-[50%] px-3 py-2 tracking-wide hover:cursor-pointer text-black text-sm" onClick={() => $("html, body").animate({ scrollTop: 0 }, "fast")}> BACK TO TOP  </button> 
        </>
    )
}

export default GalleryGrid
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './pages/views/Home';
import GalleryGrid from './pages/views/GalleryGrid';
import GalleryCarousel from './pages/views/GalleryCarousel';
import Nav from './pages/components/Nav';
import ScrollToTop from './pages/components/ScrollToTop';

function App() {

  return (
    <div class = "bg-light">
          <div class = "select-none lg:container mx-auto">
            <BrowserRouter>
              <ScrollToTop/>
              <Nav/>
              <Routes>
                <Route exact path="/" element={<Home />}></Route>
                <Route exact path="/gallery/:path" element={<GalleryGrid />}></Route>
                <Route exact path="/gallery/:path/:id" element={<GalleryCarousel />}></Route>
                <Route path="*" element={<Home />}></Route>
              </Routes>
            </BrowserRouter>
          </div>  
      </div>
  );
}

export default App;